
import { AppConfiguration } from "../../domain/entities/AppConfiguration";
import { Prestation } from "../../domain/entities/Prestation";
import { IAppConfigurationRepository } from "../../domain/interfaces/IAppConfigurationRepository";
import { HttpClient } from "./HttpClient";

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}



export class AppConfigurationRepositoryRemote implements IAppConfigurationRepository {
  
  private httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }


  async GetAppConfiguration(): Promise<AppConfiguration | null> {
    const endpoint = `/api/configurations`;
    try {
      const communes = await this.httpClient.get<AppConfiguration>(endpoint)
      return communes;
    } catch (error) {
      console.error('Erreur lors de la récupération des prestations:', error);
     throw error;
    }
  }
}


