import { Commune } from "../../entities/Commune";
import { IFraisRepository } from "../../interfaces/IFraisRepository";

export class GetCommunesUseCase {
  constructor(private fraisRepository: IFraisRepository) { }

  async execute() : Promise<Array<Commune>> {
    return this.fraisRepository.GetCommunes();
  }
}
