import React from "react";
import { Avatar, } from "@material-tailwind/react";
import { Prestation } from "../domain/entities/Prestation";
import { StickyNavbar } from "../components/StickyNavBar";
import { RatingWithText } from "../components/RatingBar";
import PhotosSwiper from "../components/PhotosSwiper";
import { useNavigate } from "react-router-dom";
import { SelectionPrestationForm } from "../components/SelectionPrestationForm/SelectionPrestationForm";
import { AppContext } from "../domain/AppContextProvider";
import { AppContextType } from "../domain/AppContextType";
import { HomePageSkeleton } from "../components/HomePageSkeleton";
import { AppStyleSheet } from "../components/Stylesheet";
import { CriticalError } from "../components/CriticalError";


export function HomeScreen() {
  const { updatePrestation, appConfiguration, isLoading, errorMsg } = React.useContext(AppContext) as AppContextType;

  let navigate = useNavigate();

  function onPrestationSelected(selectedPrestation: Prestation): void {

    updatePrestation(selectedPrestation)
    const params = new URLSearchParams(new URL(window.location.href).search);
    navigate(`/reservation?${params.toString()}`, {
      state: {
        currPrestation: selectedPrestation
      }
    });

  }

  function renderScreen() {
    return <div className="m-3">
      <AppStyleSheet />
      <div className="flex items-start items-center">
        <Avatar src={appConfiguration?.logo} className="mr-2" size="xl"></Avatar>
        <div className="flex flex-col">
          <p className="font-bold text-2xl leading-none text-secondary">{appConfiguration?.titre}</p>
          <a href="#" className="underline">{appConfiguration?.sousTitre}</a>
          {appConfiguration?.reputation != null && <RatingWithText reputation={appConfiguration!.reputation}></RatingWithText>}
        </div>

      </div>
      <p className="whitespace-pre-wrap">{appConfiguration?.description}</p>
      <div className="mt-3 mb-3 flex flex-col sm:flex-row">
        <div className="mr-3 flex flex-row space-x-2 items-center">
          <span className="h-4 rounded-full w-4 bg-green-500"></span>
          <span>Prochaine disponibilité le</span>
        </div>

        <span className="text-secondary font-bold">{Intl.DateTimeFormat("fr-FR", { dateStyle: "full", hour12: false, timeStyle: "short" }).format(Date.parse(appConfiguration?.premiereDisponibilite!))}</span>
      </div>
      {appConfiguration!.galleries!.length > 0 && <PhotosSwiper></PhotosSwiper>}
      <SelectionPrestationForm description={appConfiguration!} onStepFilled={onPrestationSelected} />
    </div>;
  }

  return (<>
    <div className="flex flex-col items-center justify-center">
      <div className="h-full w-full md:w-[600px]"><div className="">
        <StickyNavbar></StickyNavbar>
        {isLoading  ? <HomePageSkeleton /> : (errorMsg == null  ? renderScreen() : <CriticalError msg={errorMsg} />)}

      </div> </div>
    </div></>)
}