import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomeScreen } from "./screens/HomeScreen";
import { BookStepper } from "./components/BookStepper/BookStepper";
import AppProvider, { AppContext } from "./domain/AppContextProvider";

export function App() {


  return (

    <BrowserRouter>
      <AppProvider>
      <Routes>
        <Route path="/">
          <Route index element={<HomeScreen />} />
          <Route path="reservation" element={<BookStepper />} />
        </Route>
      </Routes>
      </AppProvider>
    </BrowserRouter>
  );

  
}