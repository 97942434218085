export class Commune { 
    
    public id: number;
    public codePostal: string; 
    public fraisKilometrique: number; 
    public nom: string; 

    constructor (id: number, codePostal:string, fraisKilometrique:number, nom: string){
        this.id = id;
        this.codePostal = codePostal;
        this.fraisKilometrique = fraisKilometrique;
        this.nom = nom;
    }
}
