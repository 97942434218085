import React from 'react';
import { Prestation } from "../../domain/entities/Prestation";
import { Commune } from '../../domain/entities/Commune';
import { Coordonnee } from '../../domain/entities/Coordonnee';
import { AppContextType, Reservation } from '../../domain/AppContextType';
import { AppContext } from '../../domain/AppContextProvider';

type ButtonReservationFallbackProps = {
  reservation: Reservation;
}



export function ButtonReservationFallback({ reservation }: ButtonReservationFallbackProps) {
  const { appConfiguration } = React.useContext(AppContext) as AppContextType;

  return (
    <a className='text-primary hover:text-accent' href={encodeURI('mailto:' + appConfiguration!.emailReservation + '?subject=Demande de Réservation&body=') + encodeURI(`Bonjour, je souhaiterais réserver le créneau \n${Intl.DateTimeFormat("fr-FR", { dateStyle: "medium" }).format(reservation.date!)} ${Intl.DateTimeFormat("fr-FR", { timeStyle: "short", hour12: false }).format(reservation.date!)}\n${reservation.prestation!.nom} ${reservation.prestation!.categorie} à l'adresse suivante:\n\n${reservation.coordonnee!.adresse}\n${reservation.commune!.nom} ${reservation.commune!.codePostal}\n\nMerci.\n${reservation.coordonnee!.prenom} ${reservation.coordonnee!.nom}\n${reservation.coordonnee!.telephone}`).replace("&", "")}>{appConfiguration?.emailReservation}</a>);
}
