import { ConfigService } from "../ConfigService";

export class HttpClient {
    private baseUrl: string | undefined;
    private config: ApiConfiguration | undefined;
    private locataireId: string;
  
    constructor() {
      this.config = ConfigService.Get();
      this.baseUrl = this.config?.API_HOST;
      this.locataireId = this.config.LOCATAIRE_ID;
    }
  
    private getFullUrl(url: string): string {
      if (!this.baseUrl) {
        throw new Error('API_HOST is not defined');
      }
      return `${this.baseUrl}${url}`;
    }
  
    private getDefaultHeaders(headers?: HeadersInit): HeadersInit {
      return {
        'Content-Type': 'application/json',
        'locataire-id': this.locataireId,  // Automatically include Locataire-ID in every request
        ...headers,
      };
    }
  
    public async get<T>(url: string, options?: RequestInit): Promise<T> {
      const response = await fetch(this.getFullUrl(url), {
        method: 'GET',
        headers: this.getDefaultHeaders(options?.headers),
        ...options,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return await response.json() as T;
    }
  
    public async post<T>(url: string, data: any, options?: RequestInit): Promise<T> {
      const response = await fetch(this.getFullUrl(url), {
        method: 'POST',
        headers: this.getDefaultHeaders(options?.headers),
        body: JSON.stringify(data),
        ...options,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return await response.json() as T;
    }
  
    // Add other methods like PUT, DELETE, etc., as needed
  }
  