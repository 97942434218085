import { Button, Typography } from "@material-tailwind/react";

interface CriticalErrorProps {
    msg: string
}

export function CriticalError({ msg }: CriticalErrorProps) {
    const email = 'contact@planero.fr';
    const subject = encodeURIComponent('Erreur Critique - Code 500');
    const body = encodeURIComponent(`Bonjour,\n\nUne erreur critique s'est produite. Voici les détails :\n\n- Code d'erreur : 500\n- URL : ${window.location.href}\n Merci de votre aide.`);

    return (
        <div className="flex flex-col mt-5 items-center justify-center">
            <Typography variant="h1">😢</Typography>
            <Typography variant="h2">Erreur Critique</Typography>
            <Typography variant="paragraph">{msg}</Typography>
            <a href={`mailto:${email}?subject=${subject}&body=${body}`}>
                <Button variant="filled" className="mt-5">Nous Contacter</Button>
            </a>
        </div>
    );

}