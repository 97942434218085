import { AppConfiguration } from "../../entities/AppConfiguration";
import { IAppConfigurationRepository } from "../../interfaces/IAppConfigurationRepository";

export class GetAppConfigurationUseCase {
  constructor(private appConfigurationRepository: IAppConfigurationRepository) { }

  async execute() : Promise<AppConfiguration | null> {
    return this.appConfigurationRepository.GetAppConfiguration();
  }
}
