import React, { useEffect } from "react";
import { Stepper, Step, Button, CardBody, Card, Spinner, Typography } from "@material-tailwind/react";
import { UserIcon, CalendarDaysIcon, ShoppingBagIcon, CheckBadgeIcon, StarIcon } from "@heroicons/react/24/outline";
import { RegistrationForm } from "../RegistrationForm/RegistrationForm";
import { Prestation } from "../../domain/entities/Prestation";
import { SelectionPrestationForm } from "../SelectionPrestationForm/SelectionPrestationForm";
import { SelectionCreneauForm } from "../SelectionCreneauForm/SelectionCreneauForm";
import { ConfirmationStep } from "../ConfirmationStep/BookingForm";
import { FinalStep } from "../FinalStep/FinalStep";
import { Coordonnee } from "../../domain/entities/Coordonnee";
import { Commune } from "../../domain/entities/Commune";

import { useLocation } from "react-router-dom";
import { AppContextType } from "../../domain/AppContextType";
import { AppContext } from "../../domain/AppContextProvider";
import { AppStyleSheet } from "../Stylesheet";
import { CriticalError } from "../CriticalError";

export function BookStepper() {
  const { appConfiguration, reservation, isLoading, errorMsg, updateCoordonnee, updateCommune, updatePrestation, updateCreneau } = React.useContext(AppContext) as AppContextType;
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(reservation.prestation == null ? 0 : 1);


  const handleNext = () => {
    !isLastStep && setActiveStep((cur) => cur + 1);
  }

  const goToPreviousStep = (targetIndex: number) => {
    if (targetIndex < activeStep && ! isLastStep) setActiveStep(targetIndex);
  }


  const handlePrev = () => {
    !isFirstStep && setActiveStep((cur) => cur - 1);
  }

  const onPrestationSelected = (prestation: Prestation) => {
    handleNext();
    updatePrestation(prestation);
  }

  const onCreneauPrestationSelected = (creneau: Date) => {
    handleNext();
    updateCreneau(creneau);
  }

  const onCoordonneesFilled = (coordonnees: Coordonnee, commune: Commune) => {
    updateCoordonnee(coordonnees)
    updateCommune(commune);
    handleNext();
  }


  if (isLoading) {
    return <div className="h-screen flex items-center justify-center">
      <Spinner className="h-12 w-12" />
    </div>
  }

  if (errorMsg != null) {
    return <CriticalError msg={errorMsg} />
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="h-full w-full md:w-[600px]">
        <AppStyleSheet />
        <CardBody>
          <Stepper
            activeStep={activeStep}
            isLastStep={(value) => setIsLastStep(value)}
            isFirstStep={(value) => setIsFirstStep(value)}
            lineClassName="bg-secondary text-text"
            activeLineClassName="bg-primary text-background"

          >
            <Step
              activeClassName="bg-accent text-background"
              completedClassName="bg-primary text-background"
              className="bg-secondary text-background"
              onClick={() => goToPreviousStep(0)}

            >
              <StarIcon className="h-5 w-5" />
            </Step>
            <Step
              activeClassName="bg-accent text-background"
              completedClassName="bg-primary text-background"
              className="bg-secondary text-background"
              onClick={() => goToPreviousStep(1)}
            >
              <CalendarDaysIcon className="h-5 w-5" />
            </Step>
            <Step
              activeClassName="bg-accent text-background"
              completedClassName="bg-primary text-background"
              className="bg-secondary text-background"
              onClick={() => goToPreviousStep(2)}
            >
              <UserIcon className="h-5 w-5" />
            </Step>
            <Step
              activeClassName="bg-accent text-background"
              completedClassName="bg-primary text-background"
              className="bg-secondary text-background"
              onClick={() => goToPreviousStep(3)}
            >
              <ShoppingBagIcon className="h-5 w-5" />
            </Step>
            <Step
              activeClassName="bg-accent text-background"
              completedClassName="bg-primary text-background"
              className="bg-secondary text-background">
              <CheckBadgeIcon className="h-5 w-5" />
            </Step>
          </Stepper>
          {activeStep == 0 && <SelectionPrestationForm description={appConfiguration!} onStepFilled={onPrestationSelected} />}
          {activeStep == 1 && <SelectionCreneauForm reservation={reservation} onStepFilled={onCreneauPrestationSelected} />}

          {activeStep == 2 && <RegistrationForm reservation={reservation} onStepFilled={onCoordonneesFilled} />}
          {activeStep == 3 && <ConfirmationStep reservation={reservation} onConfirmationSuccess={handleNext} />}
          {activeStep == 4 && <FinalStep reservation={reservation} />}
          {!isFirstStep && !isLastStep && <div className="mt-16 flex justify-between">
            <Button onClick={handlePrev} className="bg-secondary text-text">
              Précédent
            </Button>
          </div>}
        </CardBody>
      </div>
      </div>
  );
}