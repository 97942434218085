import { useState } from "react";
import { BookPrestationUseCase } from "../../domain/useCases/BookPrestation/BookPrestationUseCase";
import { PrestationRepositoryRemote } from "../../infrastructure/Remote/PrestationsRepositoryRemote";
import { Commune } from "../../domain/entities/Commune";
import { BookPrestationRequest } from "../../domain/useCases/BookPrestation/BookPrestationRequest";
import { Reservation } from "../../domain/AppContextType";


export const useViewModel = (reservation: Reservation, onStepFilled: () => any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<{ cge: string, system: string }>({ cge: '', system: '' });
    const [isCGEValid, setIsCGEValid] = useState(false);


    const onChangeCGV = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCGEValid(e.target.checked);
    };




    const onValidate = async () => {
        const newErrors = { cge: '', system: '' };

        // Validation du champ CGE
        if (!isCGEValid) {
            newErrors.cge = 'Veuillez accepter les CGV.';
        }
        setErrors(errors);
        if (newErrors.system || newErrors.cge) {
            return;
        }
        setIsLoading(true);
        new BookPrestationUseCase(new PrestationRepositoryRemote())
            .execute(new BookPrestationRequest(reservation.coordonnee!, reservation.prestation!.id, reservation.date!))
            .then(response => {
                setIsLoading(false);
                onStepFilled()
            })
            .catch(error => {
                errors.system = error;
                setErrors(errors);
                setIsLoading(false);
            });
    }



    return {
        isLoading, Commune, errors, onChangeCGV,isCGEValid,onValidate
    }
}



