import { useEffect, useState } from "react";

import { GroupedPrestations, Prestation } from "../../domain/entities/Prestation";
import { AppConfiguration } from "../../domain/entities/AppConfiguration";


export const useDescriptionViewModel = (description: AppConfiguration) => {

    const [groupPrestations, setGroupPrestations] = useState<GroupedPrestations>({});
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState('');
    const handleOpen = (value: string) => setOpen(open === value ? "" : value);

    function groupPrestationsByCategory(prestations: Prestation[]): GroupedPrestations {
        const groupedPrestations: { [key: string]: Prestation[] } = {};
        for (const prestation of prestations) {
            const { categorie } = prestation;
            if (!groupedPrestations[categorie]) {
                groupedPrestations[categorie] = [];
            }
            groupedPrestations[categorie].push(prestation);
        }
        return groupedPrestations;
    }

    useEffect(() => {
        var groupedPrestations = groupPrestationsByCategory(description.prestations!);
        const firstEntry = Object.entries(groupedPrestations)[0];

        setGroupPrestations(groupedPrestations);
        setIsLoading(false)
        if (firstEntry){
            const [firstCategory] = firstEntry;
            setOpen(firstCategory)
        }
     
    }, [description]);
   
    return {
        groupPrestations, isLoading, handleOpen, open
    }
}


