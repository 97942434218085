import { useEffect, useState } from "react";
import { Coordonnee } from "../../domain/entities/Coordonnee";
import { GetCommunesUseCase } from "../../domain/useCases/GetCommunes/GetCommunesUseCase";
import { Commune } from "../../domain/entities/Commune";
import { FraisRepositoryRemote } from "../../infrastructure/Remote/FraisRepositoryRemote";

export const useRegistrationFormViewModel = (currCoordonnee:Coordonnee | null | undefined, onStepFilled: (coordonnee:Coordonnee, commune:Commune) => any) => {

   const [coordonnee, setCordonnee] = useState<Coordonnee>(currCoordonnee ?? {
        adresse: "",
        email: "",
        nom: "",
        prenom: "",
        telephone: "",
        communeId:null,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [errors, setErrors] = useState<{ email:string, commune: string , telephone: string, system:string}>({ email: '', commune: '', telephone:'', system:''});
    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        let fields = Object.assign({}, coordonnee) as any;
        fields[e.currentTarget.name] = e.currentTarget.value;
        setCordonnee(fields);
    }

 
    const validateEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };    
      const validateTelephone = (telephone: string) => {
        const telephoneRegex =  /^(0|\+33)[1-9]([-.]?[0-9]{2}){4}$/;
        return telephoneRegex.test(telephone);
      };  



     const onChangeOption = (selectedCommune:any) => {
        if (selectedCommune == null) {
          let newCoordonnee ={...coordonnee,communeId:null};
          setCordonnee(newCoordonnee);
          return ;
        }
        let newCoordonnee ={...coordonnee,communeId: selectedCommune.value};
        setCordonnee(newCoordonnee);
      }; 

      const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
          
        const newErrors = { email: '', commune:'', telephone: '', system:''};
  
        // Validation du champ téléphone
        if (!validateTelephone(coordonnee.telephone)) {
          newErrors.telephone = 'Veuillez saisir un numéro de téléphone valide.';
        }

        // Validation du champ email
        if (!validateEmail(coordonnee.email)) {
          newErrors.email = 'Veuillez saisir une adresse email valide.';
        }
        if (coordonnee.communeId == null){
          newErrors.commune = 'Veuillez saisir votre commune.';
        }
        // Mise à jour des messages d'erreur
        setErrors(newErrors);
      
        // Vérification de la validité du formulaire
        if (newErrors.email || newErrors.commune || newErrors.telephone) { 
          return;
        }
        var commune = communes.find(x => x.id == coordonnee.communeId);
        onStepFilled(coordonnee,commune!);
      };
      
   

    useEffect(() => {
      new GetCommunesUseCase(new FraisRepositoryRemote())
      .execute()
      .then(response => {
          setCommunes(response);
          setIsLoading(false);
      });
    }, []);

    

    return {isLoading,coordonnee, onChange,errors,handleSubmit,communes,onChangeOption}
}


