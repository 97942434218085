import { useContext } from "react";
import { AppContext } from "../domain/AppContextProvider";
import { AppContextType } from "../domain/AppContextType";
import { Spinner } from "@material-tailwind/react";

export interface LayoutProps  { 
    children: React.ReactNode
 }
export function AppStyleSheet() {
    var appContext = useContext(AppContext) as AppContextType;
    return (
    <style>
        {`
        :root {
          --text: ${appContext.appConfiguration!.theme.text};
          --background: ${appContext.appConfiguration!.theme.background};
          --primary: ${appContext.appConfiguration!.theme.primary};
          --secondary: ${appContext.appConfiguration!.theme.secondary};
          --accent: ${appContext.appConfiguration!.theme.accent};
        }
      `}
    </style>);
}