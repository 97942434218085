import React from "react";
import { Rating, Typography } from "@material-tailwind/react";
import { Reputation } from "../domain/entities/AppConfiguration";
 

interface IRatingWithTextProps {
    reputation: Reputation
}

export function RatingWithText({reputation}: IRatingWithTextProps) {

  return (
    <div className="flex items-center gap-2 font-bold text-blue-gray-500">
      {reputation.note}
      <Rating value={Math.round(reputation.note)} readonly={true} />
      <Typography color="blue-gray" className="font-medium text-blue-gray-500">
        ({reputation.nbAvis} Avis)
      </Typography>
    </div>
  );
}