import React, { useEffect } from 'react';
import { AppContextType, Reservation } from './AppContextType';
import { Coordonnee } from './entities/Coordonnee';
import { Prestation } from './entities/Prestation';
import { Commune } from './entities/Commune';
import { ConfigService } from '../infrastructure/ConfigService';
import { GetAppConfigurationUseCase } from './useCases/GetAppConfiguration/GetAppConfigurationUseCase$';
import { AppConfiguration } from './entities/AppConfiguration';
import { AppConfigurationRepositoryRemote } from '../infrastructure/Remote/AppConfigurationRepositoryRemote';

export const AppContext = React.createContext<AppContextType | null>(null);
var fakeCoordonnee = new Coordonnee("Martinez", "quentin.martinez@outlook.com", "20 rue des colombes", "06.48.56.67.00", "Quentin", 1);

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const [appConfiguration, setAppConfiguration] = React.useState<AppConfiguration>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

    useEffect(() => { 
        new GetAppConfigurationUseCase(new AppConfigurationRepositoryRemote()).execute()
            .then(appConfiguration => {
                setAppConfiguration(appConfiguration!);
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false);
                setErrorMsg("Une erreur s'est produite lors du chargement de l'application.")
            });
        }, []);

    const [reservation, setReservation] = React.useState<Reservation>({
        coordonnee: ConfigService.Get().USE_FAKE_FILLED_FORM == "true" ? fakeCoordonnee : null,
        date: null,
        prestation: null,
        commune: null
    });




    const updateCoordonnee = (newCordonne: Coordonnee) => {
        setReservation(prevReservation => ({
            ...prevReservation,
            coordonnee: newCordonne!,
        }));
    };

    const updateCreneau = (selectedDate: Date) => {
        setReservation(prevReservation => ({
            ...prevReservation,
            date: selectedDate,
        }));
    };


    const updatePrestation = (selectedPrestation: Prestation) => {
        setReservation(prevReservation => ({
            ...prevReservation,
            prestation: selectedPrestation,
        }));
    };

    const updateCommune = (selectedCommune: Commune) => {
        setReservation(prevReservation => ({
            ...prevReservation,
            commune: selectedCommune,
        }));
    };



    return <AppContext.Provider value={{ reservation,appConfiguration, isLoading, errorMsg, updateCoordonnee, updatePrestation, updateCommune, updateCreneau }}>{children}</AppContext.Provider>;
};

export default AppProvider;