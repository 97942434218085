
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useContext } from 'react';
import { AppContext } from '../domain/AppContextProvider';
import { AppContextType } from '../domain/AppContextType';

export default () => {
  const appContext = useContext(AppContext) as AppContextType;
  return (
    <Swiper
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      className=''
    >{
        appContext.appConfiguration?.galleries.map(image => <SwiperSlide className='swiper-slide'><img src={image} className='rounded-lg object-cover h-64 w-full' /></SwiperSlide>)}
    </Swiper>
  );
};