import { BookRequestStatus } from "../../entities/BookRequestStatus";
import { IPrestationRepository } from "../../interfaces/IPrestationRepository";
import { BookPrestationRequest } from "./BookPrestationRequest";
import { BookPrestationResponse } from "./BookPrestationResponse";

export class BookPrestationUseCase {
  constructor(private prestationRepository: IPrestationRepository) { }

  async execute(request: BookPrestationRequest): Promise<BookPrestationResponse | null> {
    return this.prestationRepository.BookPrestation(request)
      .then(response => {
        var output = new BookPrestationResponse(response!.status, response!.date, null)
        if (response?.status == BookRequestStatus.Succes) {
          return output;
        }
        else if (response?.status == BookRequestStatus.CreneauIndisponible) {
          throw "Désolé le créneau " + Intl.DateTimeFormat("fr-FR", { dateStyle: "medium", hour12: false, timeStyle: "short" }).format(response.date) + " n'est plus disponible. Veuilliez en selectionner un autre.";
        }
        else {
          throw "Il semble y avoir eu un problème lors de la réservation.";
        }
      }).catch(error => {
        throw  "Il semble y avoir eu un problème lors de la réservation."
      });
 
  }
}
