export class Coordonnee {
    nom: string;
    prenom: string;
    email: string;
    adresse: string;
    telephone: string;
    communeId: number | null;
  
    constructor( nom: string, email: string,  adresse: string, telephone: string, prenom:string, communeId:number | null) {
        this.nom = nom;
        this.email = email;
        this.adresse = adresse;
        this.telephone = telephone;
        this.prenom = prenom;
        this.communeId = communeId;
    }
}
