import { Commune } from "../../domain/entities/Commune";

import { IFraisRepository } from "../../domain/interfaces/IFraisRepository";
import { HttpClient } from "./HttpClient";

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}


export class FraisRepositoryRemote implements IFraisRepository {

  private httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }


  async GetCommunes(): Promise<Commune[]> {
    const endpoint = `/api/frais/communes`;
    try {
      const communes = await this.httpClient.get<Commune[]>(endpoint)
      return communes;
    } catch (error) {
      console.error('Erreur lors de la récupération des prestations:', error);
      return [];
    }
  }
  


}
