import { Coordonnee } from "../../entities/Coordonnee";


export class BookPrestationRequest {
  Cordonnees: Coordonnee
  prestationId: number;
  date: Date;
  constructor(cordonnees: Coordonnee, prestationId: number, date: Date) {
    this.Cordonnees = cordonnees;
    this.prestationId = prestationId;
    this.date = date;
  }
}
