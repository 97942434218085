import { Typography } from "@material-tailwind/react";

export function HomePageSkeleton() {
    return (
        <div className="m-3">

            <div className="flex items-start items-center">
                <span className="h-16 rounded-full w-16 bg-gray-200 m-3"></span>
                <div className="flex-1 flex-col">
                    <div className="h-4 bg-gray-200 rounded w-3/4 mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-2/4 mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/4 mb-1"></div>
                </div>
            </div>

            <div className="block relative w-full animate-pulse">
                <p className="whitespace-pre-wrap animate-pulse">
                    <div className="h-4 bg-gray-200 rounded w-full mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-3/4 mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-3/4 mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-2/4 mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/3 mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/4 mb-1"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                </p>

                <div className="animate-pulse mt-3">
                    <div className="h-48 bg-gray-200 rounded w-full"></div>
                </div>
                <div className="overflow-hidden">
                    <div className="block w-full py-4 text-gray-700 antialiased font-sans text-sm font-light leading-normal">
                        {LineHeader()}
                        {LineHeader()}
                        {LineHeader()}
                        {LineHeader()}
                    </div>
                </div>
            </div>
        </div>
    );

    function LineHeader() {
        return <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md mt-5">
            <div className="p-2">
                <div className="flex flex-col">
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    <div className="flex flex-row items-center justify-between ">
                        <div>
                            <span className="h-4 bg-gray-200 rounded w-16 inline-block mr-3"></span>
                            <span className="h-4 bg-gray-200 rounded w-16 inline-block mr-3"></span>
                            <span className="h-4 bg-gray-200 rounded w-16 inline-block"></span>
                        </div>
                        <div className="h-10 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>;
    }
}