import { BookRequestStatus } from "../../entities/BookRequestStatus";

export class BookPrestationResponse {
      status :BookRequestStatus
      date: Date;
      msg: string | null | undefined;
      constructor(status :BookRequestStatus, date: Date, msg: string | null | undefined){
        this.status = status;
        this.date = date;
        this.msg = msg;
      }
}
