import { useEffect, useState } from "react";
import { Prestation } from "../../domain/entities/Prestation";

import { PrestationRepositoryRemote } from "../../infrastructure/Remote/PrestationsRepositoryRemote";
import { GetDatePrestationUseCase } from "../../domain/useCases/GetPrestationCreneauxUseCase";
import { Reservation } from "../../domain/AppContextType";



export const useSelectionCreneauViewModel = (reservation: Reservation) => {
    const [creneaux, setCreneaux] = useState<Map<string, Date[]>>();
    const [creneauSelectionne, setCreneauSelectionne] = useState<Date>();
    
    const [isLoading, setIsLoading] = useState(true);

    const groupDatesByDay = (dateList: Array<Date>): Map<string, Date[]> => {
        const dateMap: Map<string, Date[]> = new Map();
          dateList.forEach((date) => {
          const day = date.toISOString().split('T')[0]; // Obtient la partie de la date correspondant au jour
          if (dateMap.has(day)) {
            dateMap.get(day)?.push(date);
          } else {
            dateMap.set(day, [date]);
          }
        });
        return dateMap;
      };


    useEffect(() => {

        const fetchCreneaux = async () => {
            
        new GetDatePrestationUseCase(new PrestationRepositoryRemote()).execute(reservation.prestation!.id)
            .then(response => {
                setCreneaux(groupDatesByDay(response));
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false);
            });
        }

        fetchCreneaux();
       
    }, []);
    return {
        creneaux, isLoading
    }
}


