import { Prestation }from "../entities/Prestation";
import { IPrestationRepository } from "../interfaces/IPrestationRepository";

export class GetDatePrestationUseCase {
  constructor(private prestationRepository: IPrestationRepository) { }

  async execute(prestationId:number) : Promise<Array<Date>> {
    return this.prestationRepository.GetPrestationCreneaux(prestationId);
  }
}
