import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import { Prestation } from "../../domain/entities/Prestation";
import { CalendarDaysIcon, CheckBadgeIcon } from "@heroicons/react/24/outline";

import { AddToCalendarButton } from 'add-to-calendar-button-react';
import moment from "moment";
import { ReservationTable } from "../ReservationTable/ReservationTable";
import { Commune } from "../../domain/entities/Commune";
import { AppContextType, Reservation } from "../../domain/AppContextType";
import { AppContext } from "../../domain/AppContextProvider";
import React from "react";

type FinalStepProps = {
  reservation: Reservation;
}


export function FinalStep({ reservation }: FinalStepProps) {
  const { appConfiguration } = React.useContext(AppContext) as AppContextType;
 
  return ( <div className="flex flex-col justify-center items-center">
    <Card color="transparent" shadow={false}>
      <CheckBadgeIcon color="green" height={100} />
      <Typography className="text-center" variant="h4" color="blue-gray">
        Réservation prise en compte.
      </Typography>
      <Typography className="text-center" variant="h5" >
        {Intl.DateTimeFormat("fr-FR", { dateStyle: "medium", hour12: false }).format(reservation.date!)} {Intl.DateTimeFormat("fr-FR", { timeStyle: "short", hour12: false }).format(reservation.date!)}
      </Typography>
     
     
      <Typography color="gray" className="mt-1 font-normal" variant="lead">
        Informations de réservation
      </Typography>
      <ReservationTable reservation={reservation} />
    

      <br />
      <div className="flex items-center justify-center">
        <AddToCalendarButton
          language="fr"
          name={`Réservation ${appConfiguration?.nomSociete}`}
          description={reservation.prestation?.description}
          startDate={moment(reservation.date).format("YYYY-MM-DD")}
          startTime={moment(reservation.date).format("HH:mm")}
          endTime={moment(reservation.date).add(reservation.prestation?.duree, 'm').format("HH:mm")}
          iCalFileName={`reservation_${appConfiguration?.nomSociete?.toLowerCase()}`}
          buttonStyle="round"
          timeZone={"Europe/Paris"}
          options={['Apple', 'Google', 'Yahoo', 'iCal']}
        ></AddToCalendarButton>
      </div>

      <Typography color="gray"  className="mt-1 font-normal text-justify">
        Vous recevrez un email de confirmation. Vous aurez la possibilité d'annuler la réservation à partir de celui-ci.
      </Typography>
      <Typography color="red" className="mt-1 font-normal text-justify">
        N'oubliez pas de vérifier vos courriers indésirables.
      </Typography>
      <Typography color="black" className="mt-1 font-normal text-justify">
        {appConfiguration?.nomSociete} vous remercie pour votre confiance!
      </Typography>
    </Card>
    </div>
  );
}