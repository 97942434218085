import React from "react";
import {
  Button, CardBody, Card, Spinner, Typography, Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { Reservation } from "../../domain/AppContextType";
import { useSelectionCreneauViewModel } from "./useSelectionCreneauViewModel";

type SelectionCreneauFormProps = {
  reservation: Reservation;
  onStepFilled: (selectedDate: Date) => void;
}

export function SelectionCreneauForm({ reservation, onStepFilled }: SelectionCreneauFormProps) {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);
  const { creneaux, isLoading } = useSelectionCreneauViewModel(reservation);
  if (isLoading) {
    return <div className="h-screen flex items-center justify-center">
      <Spinner className="h-12 w-12" />
    </div>
  }


  return (<div className="h-full flex flex-col mt-2">


    {Array.from(creneaux!).map(([day, dates], index: number) => (


      <Accordion open={open === index}>
        <AccordionHeader 
        className="border-accent"
        
        onClick={() => handleOpen(index)}>{
          Intl.DateTimeFormat("fr-FR",
            { weekday: 'long', hour12: false }).format(Date.parse(day)) + " " + Intl.DateTimeFormat("fr-FR",
              { dateStyle: 'medium', hour12: false }).format(Date.parse(day))}</AccordionHeader>
        <AccordionBody>
          <div className="grid grid-cols-2 gap-2 justify-between">
            {dates.map((date, index) => (
              <Button key={index}
                className={date.getTime() == reservation.date?.getTime() ? "bg-accent text-background" : "bg-primary text-background"}
                onClick={() => onStepFilled(date)}>{new Intl.DateTimeFormat("fr-FR",
                  { timeStyle: 'short', hour12: false }
                ).format(date)}</Button>
            ))}
          </div>

        </AccordionBody>
      </Accordion>


    ))}


  </div>);
}