import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Spinner,
  Option
} from "@material-tailwind/react";
import { useRegistrationFormViewModel } from "./useViewModel";
import { Coordonnee } from "../../domain/entities/Coordonnee";
import { Commune } from "../../domain/entities/Commune";
import Select from 'react-select'
import { Reservation } from "../../domain/AppContextType";

type RegistrationFormStepProps = {
  onStepFilled: (coordonnees:Coordonnee, commune:Commune)  => void;
  reservation: Reservation | null | undefined;
}


export function RegistrationForm({  onStepFilled, reservation}: RegistrationFormStepProps) {
  var { coordonnee, onChange, handleSubmit, errors, isLoading, communes, onChangeOption } = useRegistrationFormViewModel(reservation!.coordonnee, onStepFilled);
  const options = communes.map(commune => ({
    value: commune.id,
    label: `${commune.nom} (${commune.codePostal})`
  }));

  const customStyles = {
    control: (base:any, state:any) => ({
      ...base,
      background: " var(--background)",
    }),
  };
  return (
    <Card color="transparent" shadow={false}>
      <Typography variant="h4" color="blue-gray">
        Informations
      </Typography>
      <Typography color="gray" className="mt-1 font-normal">
        Entrez vos informations pour réserver.
      </Typography>
      {errors.system && <Typography color="red" className="mt-1 font-normal mb-3">
        {errors.system}
      </Typography>}
      <form className="h-full w-full" onSubmit={handleSubmit}>
        <div className="mb-4 flex flex-col gap-6">
          <Input size="lg" label="Prénom" name="prenom"  value={coordonnee?.prenom} required={true} crossOrigin={undefined} onChange={onChange} type={"text"} />
          <Input size="lg" label="Nom" name="nom"  value={coordonnee?.nom}  required={true} crossOrigin={undefined} onChange={onChange} type={"text"} />
          <Input size="lg" label="Email" name="email" value={coordonnee?.email} required={true} crossOrigin={undefined} onChange={onChange} type={"email"} />
          {errors.email && <p className="text-red-600">{errors.email}</p>}
          <Input size="lg" label="Téléphone" name="telephone" data-testid="telephone" value={coordonnee?.telephone} required={true} crossOrigin={undefined} onChange={onChange} type={"tel"} />
          {errors.telephone && <p data-testid="telephoneErrorMsg" className="text-red-600">{errors.telephone}</p>}
          <Input size="lg" label="Adresse" name="adresse" value={coordonnee?.adresse} required={true} crossOrigin={undefined} onChange={onChange} type={"text"} />
        
          <Select name="Commune" aria-label="Commune" onChange={onChangeOption} placeholder="Indiquez votre commune" options={options}  isClearable={true}  styles={customStyles}  required={true} />
        
          {errors.commune && <p data-testid="communeErrorMsg" className="text-red-600">{errors.commune}</p>}
        </div>
     
        <Button className="mt-6 bg-primary text-background"  fullWidth  type="submit" disabled={false}>
          {isLoading ? <center><Spinner className="h-4 w-4" /></center> : "Valider"}
        </Button>
      </form>
    </Card>
  );
}