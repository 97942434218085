import { Reservation } from "../../domain/AppContextType";
import { Commune } from "../../domain/entities/Commune";
import { Prestation } from "../../domain/entities/Prestation";


type ReservationTableProps = {
    reservation: Reservation;
}
export function ReservationTable({reservation }: ReservationTableProps) {
    return (
    <table>
        <tbody>
             <tr>
            <td className="font-bold w-1/4">Date</td>
            <td> {Intl.DateTimeFormat("fr-FR", { dateStyle: "medium" }).format(reservation.date!)}
            </td>
        </tr>
        <tr>
            <td className="font-bold min-w-1/4">Heure</td>
            <td> {Intl.DateTimeFormat("fr-FR", { timeStyle: "short", hour12: false }).format(reservation.date!)}
            </td>
        </tr>
        <tr>
            <td className="font-bold">Duree</td>
            <td>{Math.floor(reservation.prestation!.duree / 60)}H{reservation.prestation!.duree % 60}min</td>
        </tr>
        <tr>
            <td className="font-bold">Service</td>
            <td>{reservation.prestation?.nom} {reservation.prestation?.description} </td>
        </tr>
        <tr>
            <td className="font-bold">Prix</td>
            <td>{Intl.NumberFormat("fr-FR").format(reservation.prestation!.prix)}€</td>
        </tr>
        {reservation.commune && <tr>
            <td className="font-bold">Frais </td>
            <td>{reservation.commune!.nom} {Intl.NumberFormat("fr-FR").format(reservation.commune!.fraisKilometrique)}€</td>
        </tr>}
        <br/>
        <tr>
            <td className="font-bold">Total TTC</td>
            <td className="font-bold">{Intl.NumberFormat("fr-FR").format(reservation.prestation!.prix + (reservation.commune?.fraisKilometrique ?? 0))}€</td>
        </tr>
        </tbody>
    </table>)

}