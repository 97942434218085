import { Prestation } from "../../domain/entities/Prestation";
import { IPrestationRepository } from "../../domain/interfaces/IPrestationRepository";
import { BookPrestationRequest } from "../../domain/useCases/BookPrestation/BookPrestationRequest";
import { BookPrestationResponse } from "../../domain/useCases/BookPrestation/BookPrestationResponse";

import { HttpClient } from "./HttpClient";

export class PrestationRepositoryRemote implements IPrestationRepository {
  
  private httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }



  async BookPrestation(request: BookPrestationRequest): Promise<BookPrestationResponse | null> {

    try {
      const obj = await this.httpClient.post<any>(`/api/prestations/book`, request);
      return Promise.resolve(new BookPrestationResponse(obj.status, new Date(obj.date), null));
    } catch (error) {
      console.error('Erreur BookPrestation:', error);
      return null;
    }
  }

  async GetPrestationCreneaux(prestationId: number): Promise<Array<Date>> {
    try {
      const creneaux = await this.httpClient.get<string[]>(`/api/prestations/${prestationId}/creneaux`);
      var output = creneaux.map(str => new Date(str));
      return Promise.resolve(output)
    } catch (error) {
      console.error('Erreur GetPrestationCreneaux:', error);
      return [];
    }
  }


  async GetPrestationsDisponible(): Promise<Array<Prestation>> {
    try {
      const prestations = await this.httpClient.get<Array<Prestation>>(`/api/prestations`);
      return prestations;
    } catch (error) {
      console.error('Erreur GetPrestationsDisponible:', error);
      return [];
    }
  }
}
